<template>
  <div>
    <hr class="hr-M" />
    <b-row class="py-2">

       <b-col sm="12" md="12" lg="6">
        <div class="form-group fieldContent pb-2">
          <label class="field-title"
            >Descrição<span class="colorRed">*</span>
          </label>
          <input
            type="text"
            id="example1"
            class="form-control rounded-0 field-input"
          />
        </div>
      </b-col>

      <b-col sm="12" md="12" lg="8" class="pt-4">
        <div class="form-group fieldContent">
          <label class="field-title"
            >Validade farmácia popular dias<span class="colorRed">*</span>
          </label>
          <input
            type="text"
            id="example1"
            class="form-control rounded-0 field-input"
          />
        </div>
      </b-col>
    </b-row>

    <hr class="hr-position" />

    <!-- Buttons -->
    <b-row class="py-4 position-btn">
      <b-col class="text-left">
        <b-button
          id="show-btn"
          @click="$bvModal.show('modal-classe-edit')"
          class="btnConfirm"
          variant="success"
        >
          Confirmar
        </b-button>
        <b-button @click="cancelF()" class="btnCancel" variant="danger">
          Cancelar
        </b-button>
      </b-col>
    </b-row>
    
     <b-modal id="modal-classe-edit" hide-footer>
      <div class="d-block text-center content-modelGeneral">
        <h3 class="model-content1">
          Cadastro editado com <br>
          <span class="success-alert"> Sucesso!</span>
        </h3>
          </div>
    </b-modal>

  </div>
</template>

<script>
export default {
  name: "formEditGeral",
  methods: {
    cancelF() {
      this.$router.push("/classe");
    },
  },
};
</script>

<style>
/*   Buttons   */

.btnCancel {
  background: #cf0209 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  width: 180px;
  height: 40px;
  font-weight: bold;
  border-radius: 5px;
  border-color: #cf0209;
  margin-left: 5px;
  box-shadow: 6px 6px 8px #53535359;
  font-family: "Segoe_UI_Black_2";
}

.btnConfirm {
  background: #27a844 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  width: 180px;
  height: 40px;
  font-weight: bold;
  border-radius: 5px;
  border-color: #27a844;
  box-shadow: 6px 6px 8px #53535359;
  font-family: "Segoe_UI_Black_2";
}

.btnConfirm:hover {
  background: #27a844 0% 0% no-repeat padding-box;
  color: #fff;
}

.mini-btnIncluir {
  background: #cf0209 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  width: 64px;
  height: 32px;
  font-size: 1em;
  border-radius: 5px;
  border-color: #cf0209;
  font-family: "Segoe_UI_Bold";
}

.colorRed {
  color: #cf0209;
  padding-left: 2px;
}

.position-btn {
  position: relative;
  top: 25vh;
}

.hr-position {
  width: 70% !important;
}

@font-face {
  font-family: "Segoe_UI_Bold";
  src: local("Segoe_UI_Bold"),
    url(~@/assets/font/Segoe_UI_Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Segoe_UI_Black_2";
  src: local("Segoe_UI_Bold"),
    url(~@/assets/font/Segoe_UI_Black_2.ttf) format("truetype");
}

/*   Fields */
.fieldContent {
  margin: 0px !important;
  padding: 0px !important;
  background-color: #e6e6e6;
  text-align: left;
  border-radius: 5px 5px 0px 0px !important;
}

.field-title {
  margin: 0px 0px 0px 6px !important;
  background-color: #e6e6e6;
  color: #272727;

  font-size: 10px;
  font-weight: bold;
  color: #272727;
}

.field-input {
  margin: 0px !important;
  padding: 0px 0px 0px 6px !important;
  height: 37px;
  font-size: 14px;
  font-weight: bold;
  color: #272727;
  text-align: left !important;
  outline: none !important;
  border-width: 0 0 3px !important;
  border-bottom: 3px solid #272727 !important;
  background-color: #e6e6e6;
}

.form-control:focus {
  color: #495057;
  background-color: #e6e6e6;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}
.success-alert{
   color: #cf0209;
  font-size: 1.2em;
  font-family: "Segoe_UI_Black_2";
}

/*  Mobile  */

@media (max-width: 992px) {
  .checkCR {
    padding-top: 10px;
    text-align: center;
    display: flex;
    justify-content: start;
  }
  .title-form {
    padding-right: 40px;
    color: #cf0209;
    position: relative;
    top: 15px;
  }
  .fieldMpdd {
    padding-top: 10px;
  }

  .hr-position {
    width: 100% !important;
    position: initial;
  }

  .hr-M {
    width: 100% !important;
    position: initial;
  }

  .position-btn {
    top: 20vh;
  }
  .btnCancel {
    width: 120px;
    height: 35px;
    font-size: 0.9em;
  }
  .btnConfirm {
    width: 120px;
    height: 35px;
    font-size: 0.9em;
  }

  .model-content1 {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 25px;
  font-size: 1.2em;
}

.modal.show .modal-dialog {
  position: relative;
  top: 20%;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100% !important;
  height: 130px !important;
}
}
</style>